import { template as template_c89d1607216c43808e2b6339046c9064 } from "@ember/template-compiler";
const FKControlMenuContainer = template_c89d1607216c43808e2b6339046c9064(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
