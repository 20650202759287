import { template as template_08b39283b4d74af09b05b735ba9705b4 } from "@ember/template-compiler";
const FKLabel = template_08b39283b4d74af09b05b735ba9705b4(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
