import { template as template_28a3af2feece47a19551a48861191a96 } from "@ember/template-compiler";
const FKText = template_28a3af2feece47a19551a48861191a96(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
